import { components } from "react-select";
import { useCallback, useRef } from "react";
import { allMateriales } from "./materiales";
import * as XLSX from 'xlsx/xlsx.mjs';

export function customFilter(productsArr, monto, selectedComparator, name) {
  const newProductsArr = [];

  productsArr.forEach(function (prod) {
    if (selectedComparator === "=") {
      if (parseInt(prod[name]) === parseInt(monto)) newProductsArr.push(prod);
    } else if (selectedComparator === ">=") {
      if (parseInt(prod[name]) >= parseInt(monto)) newProductsArr.push(prod);
    } else if (selectedComparator === "<=") {
      if (parseInt(prod[name]) <= parseInt(monto)) newProductsArr.push(prod);
    } else newProductsArr.push(prod);
  });

  return newProductsArr;
}

export function checkNumericSelectValue(comparatorValue, selectedComparator, valueToBeCompared){
  console.log("comparing:: ")
  console.log(comparatorValue)
  console.log(selectedComparator)
  console.log(valueToBeCompared)
  if (selectedComparator === "=") {
    if (parseFloat(valueToBeCompared) !== parseFloat(comparatorValue)) 
      return false
  } else if (selectedComparator === ">=") {
    if (!(parseFloat(valueToBeCompared) >= parseFloat(comparatorValue)))
      return false
  } else if (selectedComparator === "<=") {
    if (!(parseFloat(valueToBeCompared) <= parseFloat(comparatorValue)))
      return false
  }
  return true
}

export const Option = (props) => {
  return (
    <div
      className={`${
        props.value === "borrar" || props.value === "todo"
          ? "modified-option-select"
          : ""
      }`}
    >
      <components.Option {...props}>
        {props.value !== "borrar" && props.value !== "todo" && (
          <>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />
            <label>{props.label}</label>
          </>
        )}
        {(props.value === "borrar" || props.value === "todo") && (
          <>
            <label className="modified-option">{props.label}</label>
          </>
        )}
      </components.Option>
    </div>
  );
};

export const SingleOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="single-option-label">{props.label}</div>
      </components.Option>
    </div>
  );
};

export function parseBudat(budat) {
  if (!budat || budat === "") return "";

  const dateArr = budat.split("-");
  return dateArr[2] + "/" + dateArr[1] + "/" + dateArr[0];
}

export function parseDate(date) {
  if(!date)
    return ''
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) day = "0" + day.toString();

  if (month < 10) month = "0" + month.toString();

  return day + "/" + month + "/" + year;
}

export function parseDateAmerican(date) {
  if(!date)
    return ''
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) day = "0" + day.toString();

  if (month < 10) month = "0" + month.toString();

  return year + "-" + month + "-" + day;
}

export const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    minHeight: 35,
    border: "1px solid #7c97ab",
    width: state.selectProps.width,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 15px",
    fontSize: "12px",
    fontWeight: "500"
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "1px",
    border: "1px solid #809cb2",
    boxShadow: "none",
    boxSizing: "content-box",
    minWidth: "200px",
    maxMenuHeight: "100px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "normal",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "500",
    color: "#003965",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "500",
    fontSize: "12px",
  }),
};

export const customStylesPedidos = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    height: 32,
    border: "1px solid #7c97ab",
    background: state.isDisabled ? "#f1f5f8" : "white",
    width: state.selectProps.width,
  }),
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.containerWidth,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 15px",
    fontSize: "12px",
    fontWeight: "500",
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "-2px",
    border: "1px solid #809cb2",
    boxShadow: "none",
    boxSizing: "content-box",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    minWidth: state.selectProps.widthmenu?state.selectProps.widthmenu:"200px",
    maxMenuHeight: "100px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "normal",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "600",
    color: "#003965",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "600",
    fontSize: "12px",
  }),
};

export const customStylesTctTae = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    height: 26,
    minHeight: 32,
    border: "1px solid #7c97ab",
    background: state.isDisabled ? "#f1f5f8" : "white",
    width: state.selectProps.width? state.selectProps.width: 162
  }),
  valueContainer: (base, state) => ({
    ...base,
    position: "unset",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }),
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.containerWidth,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 15px",
    fontSize: "12px",
    fontWeight: "500",
    "&:hover": {
      background: "#f1f5f8"
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "-2px",
    border: "1px solid #809cb2",
    boxShadow: "none",
    boxSizing: "content-box",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    width: state.selectProps.width? `calc(${state.selectProps.width} - 2px)`: 160,
    maxMenuHeight: "100px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    marginTop: "-3px"
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "600",
    color: "#003965",
  }),
  singleValue:(provided, state) => ({
    ...provided,
    color: "#003965",
    maxWidth: "calc(100% - 40px)"
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "600",
    fontSize: "12px",
  }),
};

export const customStylesHistorialRegularizacion = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    minHeight: 32,
    border: "none",
    width: "110px",
    height: 16,
    margin: "0 0 4px 2px",
    display: "inline-table"
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 0",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "left"
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "-6px",
    marginLeft: "1px",
    border: "1px solid #dee5ea",
    borderTop: "none",
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    boxShadow: "none",
    boxSizing: "content-box",
    maxWidth: "110px",
    minWidth: "110px",
    maxMenuHeight: "70px",
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "5px"
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderTop: "1px solid #dee5ea"
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "normal",
    paddingTop: "5px",
  }),
  valueContainer:(provided, state) => ({
    ...provided,
    position: "unset",
    background: "white",
    borderRadius: "4px",
    minHeight: "32px"
  }),
  singleValue:(provided, state) => ({
    ...provided,
    color: "#003965"
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "500",
    color: "#003965"
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none"
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "500",
    fontSize: "12px"
  }),
  
};

export const customStylesRegularizacion = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    minHeight: 32,
    border: "1px solid #7c97ab",
    width: "109px",
    height: 16
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 0",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "left"
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "-3px",
    border: "1px solid #809cb2",
    borderTop: "none",
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    boxShadow: "none",
    boxSizing: "content-box",
    maxWidth: "77px",
    minWidth: "77px",
    maxMenuHeight: "70px",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "5px"
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderTop: "1px solid #dee5ea"
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "normal",
    paddingTop: "5px",
  }),
  valueContainer:(provided, state) => ({
    ...provided,
    position:"unset"
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "500",
    color: "#003965",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "500",
    fontSize: "12px",
  }),
};

export const customStylesRegularizacionTable = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    minHeight: 32,
    border: "none",
    width: "98px",
    height: 16,
    margin: "8px 0 4px 2px",
    display: "inline-table"
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 0",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "left"
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "-6px",
    marginLeft: "1px",
    border: "1px solid #dee5ea",
    borderTop: "none",
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    boxShadow: "none",
    boxSizing: "content-box",
    maxWidth: "88px",
    minWidth: "88px",
    maxMenuHeight: "70px",
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "5px"
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderTop: "1px solid #dee5ea"
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "normal",
    paddingTop: "5px",
  }),
  valueContainer:(provided, state) => ({
    ...provided,
    position: "unset",
    background: "white",
    borderRadius: "4px",
    minHeight: "32px"
  }),
  singleValue:(provided, state) => ({
    ...provided,
    color: "#003965"
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "500",
    color: "#003965"
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none"
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "500",
    fontSize: "12px"
  }),
};

export const orderArray = (filterkey, arr, order, parse) => {
  const arrfinal = [...arr];

  if (order === "asc") {
    if (parse) {
      return arrfinal.sort((a, b) =>
        parseInt(a[filterkey]) > parseInt(b[filterkey]) ? 1 : -1
      );
    } else {
      return arrfinal.sort((a, b) => (a[filterkey] > b[filterkey] ? 1 : -1));
    }
  } else {
    if (parse) {
      return arrfinal.sort((a, b) =>
        parseInt(a[filterkey]) > parseInt(b[filterkey]) ? -1 : 1
      );
    } else {
      return arrfinal.sort((a, b) => (a[filterkey] > b[filterkey] ? -1 : 1));
    }
  }
};

function getCleanVentana(ventana){
  if(ventana.length < 5){
    ventana = '0' + ventana
  }

  ventana.replace(/:/g, '')
  return ventana
}

function orderHorarioGroups(tmpArr){
  const arrfinal = []
  const fixedHourArr = []
  const arrMadrugada = []
  const arrAM = []
  const arrPM = []
  const arrNoche = []
  const arrHabil = []
  const arrCualquie = []
  const arrNotFound = []

  tmpArr.forEach(function(tmpEntry){
    if(tmpEntry.VENTANA.length <= 5){ // Agrupar horarios fijos
      fixedHourArr.push(tmpEntry)
    }
    else if(tmpEntry.VENTANA === '00:00-08:00 Madrugad'){ //Agrupar ventanas de horario
      arrMadrugada.push(tmpEntry)
    }
    else if(tmpEntry.VENTANA === '08:00-14:00 AM'){ //Agrupar ventanas de horario
      arrAM.push(tmpEntry)
    }
    else if(tmpEntry.VENTANA === '14:00-20:00 PM'){ //Agrupar ventanas de horario
      arrPM.push(tmpEntry)
    }
    else if(tmpEntry.VENTANA === '20:00-23:59 Noche'){ //Agrupar ventanas de horario
      arrNoche.push(tmpEntry)
    }
    else if(tmpEntry.VENTANA === '08:00-18:00 Habil' || tmpEntry.VENTANA === '08:00-18:00 Hábil'){ //Agrupar ventanas de horario
      arrHabil.push(tmpEntry)
    }
    else if(tmpEntry.VENTANA === '00:00-23:59 Cualquie'){ //Agrupar ventanas de horario
      arrCualquie.push(tmpEntry)
    }
    else{
      arrNotFound.push(tmpEntry)
    }
  })

  fixedHourArr.sort((a, b) => (getCleanVentana(a.VENTANA) < getCleanVentana(b.VENTANA) ? -1 : 1));

  arrfinal.push(...fixedHourArr)
  arrfinal.push(...arrMadrugada)
  arrfinal.push(...arrAM)
  arrfinal.push(...arrPM)
  arrfinal.push(...arrNoche)
  arrfinal.push(...arrHabil)
  arrfinal.push(...arrCualquie)
  arrfinal.push(...arrNotFound)

  if(arrNotFound.length > 0){
    console.log("No se encontraron horarios: ")
    console.log(arrNotFound)
  }
  return arrfinal
}

export const orderByHorario = (arr) => {
  const arrfinal = [];
  let tmpArr = []
  arr.forEach(function(entry){
    if(tmpArr.length == 0){
      tmpArr.push(entry)
    }
    else{
      if(tmpArr[0].FECHA_PREFERENTE_ENTREGA === entry.FECHA_PREFERENTE_ENTREGA){
        tmpArr.push(entry)
      }
      else{
        //order tmp aray by date and push it to arrfinal
        const tmpOrdered = orderHorarioGroups(tmpArr)
        arrfinal.push(...tmpOrdered)

        //Borrar tmpArr y agregarle solo la entrada actual
        tmpArr = [entry]
      }
    }
  })

  //Ordenar las ultimas fechas:
  const tmpOrdered = orderHorarioGroups(tmpArr)
  arrfinal.push(...tmpOrdered)
  return arrfinal
};

export function getLocaleStringNumber(num, returnZero=null) {

  if(returnZero){
    if(!num)
    return 0
  }
    
  if(num === 0 || num === '0')
    return '0'

  if(!num)
    return ''
  
  let localeNumber = num.toLocaleString("en")
  localeNumber = localeNumber.replace(/\./g, "c")
  localeNumber = localeNumber.replace(/,/g, "p")
  localeNumber = localeNumber.replace(/p/g, ".")
  localeNumber = localeNumber.replace(/c/g, ",")
  return localeNumber
}

export function checkSelectedOptions(
  filteredOptions,
  setFilteredOptions,
  allOptions
) {
  if (filteredOptions.find((e) => e.value === "borrar")) {
    setFilteredOptions([]);
  }

  if (filteredOptions.find((e) => e.value === "todo")) {
    const newFilteredOptions = allOptions.filter(function (e) {
      return e.value !== "todo" && e.value !== "borrar";
    });
    setFilteredOptions(newFilteredOptions);
  }
}

export function useThrottleCallback(method, wait) {
  const argsRef = useRef(null);
  const timeout = useRef(null);
  const throttledCallback = useCallback(
    (...args) => {
      if (!timeout.current) {
        argsRef.current = args;
        timeout.current = setTimeout(() => {
          if (argsRef.current) {
            method(...argsRef.current);
          }
          timeout.current = null;
        }, wait);
      }
    },
    [wait, method]
  );
  return throttledCallback;
}

export const spaceAboveIframe = 98; /*Bread crumbs + Header height */
export function getFixedBottomCoordinate(viewPortHeight, iframeHeight) {
  return iframeHeight - (viewPortHeight - spaceAboveIframe) /*Header height */;
}

export function getCleanEdsNumber(eds){
  return eds.slice(eds.length - 5)
}

export function checkIfTomorrow(date){
  const today = new Date()
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
  if (tomorrow.getFullYear() === date.getFullYear() && tomorrow.getMonth() === date.getMonth() && tomorrow.getDate() === date.getDate())
    return true

  return false
}

export function findValidCamion(defaultCamion, camiones, compartimientosPedido, materiales, setCamion, setCompartimientos, setCapacidadCamion = null){
  compartimientosPedido.sort((a, b) => (a.CANTIDAD < b.CANTIDAD ? 1 : -1))

  if(checkValidCamion(defaultCamion, compartimientosPedido, materiales, setCamion, setCompartimientos, setCapacidadCamion))
    return

  camiones.forEach(function(cam){
    if(checkValidCamion(cam, compartimientosPedido, materiales, setCamion, setCompartimientos, setCapacidadCamion))
      return
  })
}

export function checkValidCamion(camion, compartimientosPedido, materiales, setCamion, setCompartimientos, setCapacidadCamion){
  let compCount = 0
  let emptyComp = false
  let valid = true
  
  camion.COMPARTIMIENTOS.forEach(function(comp, key){
    let newMaterial = {'NOMBRE': 'Vacío', 'ID_MATERIAL': 'empty'}
    if(compCount < compartimientosPedido.length
      && (comp.VOLUMEN_MAXIMO === compartimientosPedido[compCount].CANTIDAD 
      || comp.VOLUMEN_MINIMO === compartimientosPedido[compCount].CANTIDAD)
    ){
      comp.volumen = compartimientosPedido[compCount].CANTIDAD
      if(materiales.find(e => e.ID_MATERIAL === compartimientosPedido[compCount].ID_MATERIAL)){
        newMaterial = materiales.find(e => e.ID_MATERIAL === compartimientosPedido[compCount].ID_MATERIAL)
        comp.posnr = compartimientosPedido[compCount].POSNR
      }
      compCount ++
    }
    else if(!emptyComp && key > 0)
      emptyComp = true
    else
      valid = false

    comp.material = newMaterial

    if(newMaterial.ID_MATERIAL === 'empty')
      comp.volumen = 0
  })

  if(valid && compCount === compartimientosPedido.length){
    setCamion(camion)
    setCompartimientos(camion.COMPARTIMIENTOS)
    if(setCapacidadCamion)
      setCapacidadCamion(parseInt(camion.NOMBRE.split(" ")[0])*1000)
  }

  return valid
}

export function getForma (arr93, arr95, arr97, arrPd, arrK, forma, nombre) {
  return {
    name: nombre,
    g93: getLitros(arr93, forma),
    g95: getLitros(arr95, forma),
    g97: getLitros(arr97, forma),
    pd: getLitros(arrPd, forma),
    k: getLitros(arrK, forma),
    total:
      getLitros(arr93, forma) +
      getLitros(arr95, forma) +
      getLitros(arr97, forma) +
      getLitros(arrPd, forma) +
      getLitros(arrK, forma),
  };
};

export function getLitros (array, forma) {
  let litros = 0;
  array.forEach((pedido) => {
    if (pedido.CATEGORIA_ITEM === forma) {
      litros += pedido.CANTIDAD_SOLICITADA;
    }
  });
  return litros;
};

export function removeCeros (str) {
  let aux = str;

  while (aux.charAt(0) === "0") {
    aux = aux.substring(1);
  }

  return aux;
};

export function getBancoName (str) {
  if(str==="ABN"){
    return "ABN AMRO BANK (CHILE)";
  }else if(str==="ARGEN"){
    return "BANCO DE LA NACION ARGENTINA"
  }else if(str==="BBVA"){
    return "BANCO BBVA"
  }else if(str==="BCI"){
    return "BANCO DE CREDITO E INVERSIONES"
  }else if(str==="BICE"){
    return "BANCO BICE"
  }else if(str==="BRASI"){
    return "BANCO DO BRASIL"
  }else if(str==="CHILE"){
    return "BANCO DE CHILE"
  }else if(str==="CITI"){
    return "CITIBANK"
  }else if(str==="CORPB"){
    return "CORPBANCA"
  }else if(str==="DESAR"){
    return "BANCO DEL DESARROLLO"
  }else if(str==="DEUTS"){
    return "DEUTSCHE BANK (CHILE)"
  }else if(str==="EDWAR"){
    return "BANCO EDWARDS"
  }else if(str==="ESTAD"){
    return "BANCO DEL ESTADO DE CHILE"
  }else if(str==="FALAB"){
    return "BANCO FALABELLA"
  }else if(str==="HNS"){
    return "RABOBANK"
  }else if(str==="HSBC"){
    return "HSBC BANK (CHILE)"
  }else if(str==="INTER"){
    return "BANCO INTERNACIONAL"
  }else if(str==="ITAU"){
    return "BANCO ITAU"
  }else if(str==="JPMOR"){
    return "JP MORGAN CHASE BANK, N.A."
  }else if(str==="MONEX"){
    return "BANCO CONSORCIO"
  }else if(str==="PARIS"){
    return "BANCO PARIS"
  }else if(str==="PENTA"){
    return "BANCO PENTA"
  }else if(str==="RIPLE"){
    return "BANCO RIPLEY"
  }else if(str==="SANTA"){
    return "BANCO SANTANDER"
  }else if(str==="SCOTI"){
    return "SCOTIABANK"
  }else if(str==="SECUR"){
    return "BANCO SECURITY"
  }else if(str==="TOKYO"){
    return "THE BANK OF TOKYO-MITSUBISHI UFJ, LTD"
  }
}

export function getBancoCode (str) {
  if(str==="BCI"){
    return "001"
  }else if(str==="CHILE"){
    return "003"
  }else if(str==="ESTAD"){
    return "004"
  }else if(str==="SANTA"){
    return "002"
  }else if(str==="SCOTI"){
    return "005"
  }
}

export function get3DecimalsFloatWithDelimiter(value) {
  if(value.length === 0)
    return '0'
  
  let isComma = false
    
  if(value.length > 1 && value.substr(value.length - 1) === ','){
    if(value.split(",").length - 1 > 1) //Si hay mas de 1 coma
      return false
    isComma = true
  }

  if(value.split(",").length - 1 == 1){ //Si hay 1 coma
    if(value.split(",")[1].length > 3) //Si hay mas de 3 decimales
      return false
  }

  value = value.replace(/\./g, "")
  value = value.replace(/,/g, ".")

  if(isNaN(value))
    return false

    value = parseFloat(value);
    let localeNumber = value.toLocaleString("en");
    localeNumber = localeNumber.replace(/\./g, "*");
    localeNumber = localeNumber.replace(/,/g, ".");
    localeNumber = localeNumber.replace(/\*/g, ",");
    
  if(isComma)
    localeNumber = localeNumber + ','

  return localeNumber;
}

export function setMaterialesYTanques(tanques, setTanques, setMateriales, setMaterialesTanques = null){
  const newMateriales = []
  const newMaterialesTanques = []
  const newTanques = []

  tanques.forEach(function(tanque){
    if(parseInt(tanque.ID) > parseInt(tanque.SIFON_1) || parseInt(tanque.ID) > parseInt(tanque.SIFON_2))
      return

    let label = tanque.TIPO === "Tanque"? `Tanque ${tanque.ID}`: tanque.ID
    let value = tanque.ID
    let capacidad = tanque.CAPACIDAD

    if(tanque.SIFON_1 !== ''){
      label += ` - ${tanque.SIFON_1}`
      value += `,${tanque.SIFON_1}`
      const sifon = tanques.find(t => t.ID === tanque.SIFON_1)
      capacidad += sifon.CAPACIDAD
    }

    if(tanque.SIFON_2 !== ''){
      label += ` - ${tanque.SIFON_2}`
      value += `,${tanque.SIFON_2}`
      const sifon = tanques.find(t => t.ID === tanque.SIFON_2)
      capacidad += sifon.CAPACIDAD
    }

    newTanques.push({ 
      label: label, 
      value: value, 
      idMaterial: tanque.ID_MATERIAL, 
      material: tanque.MATERIAL, 
      tipo: tanque.TIPO,
      capacidad: capacidad
    })
    
    const found = newMateriales.find(m => m.value === tanque.ID_MATERIAL)
    if(!found){
      newMateriales.push({ label: tanque.MATERIAL, value: tanque.ID_MATERIAL})
    }

    const foundTanques = newMaterialesTanques.find(m => m.value === tanque.ID_MATERIAL)
    if(!foundTanques && tanque.TIPO === "camion"){
      newMaterialesTanques.push({ label: tanque.MATERIAL, value: tanque.ID_MATERIAL})
    }
  })

  setTanques(newTanques)
  if(setMateriales)
    setMateriales(newMateriales)
  if(setMaterialesTanques)
    setMaterialesTanques(newMaterialesTanques)
}

export function arraycompare(array1, array2){
  const array2Sorted = array2.slice().sort();
  const equals = array1.length === array2.length && array1.slice().sort().every(function(value, index) {
    return value === array2Sorted[index];
  });

  return equals
}

export function getCantidadTotal(pedido){
  const arr93 = [];
      const arr95 = [];
      const arr97 = [];
      const arrPd = [];
      const arrK = [];
      let existenReposiciones = false;

      if (pedido.DETALLE) {
        const detalle = pedido.DETALLE;

        detalle.forEach((item) => {
          if (allMateriales[item.ID_MATERIAL].class === "sp93") {
            arr93.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp95") {
            arr95.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp97") {
            arr97.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "pd") {
            arrPd.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "k") {
            arrK.push(item);
          }
          if (item.CATEGORIA_ITEM != "ZCRM" && existenReposiciones === false) {
            existenReposiciones = true;
          }
        });
      }

      const volumenInfo = {
        93: { litros: arr93.length > 0 ? getLitros(arr93, "ZCRM") : 0 },
        95: { litros: arr95.length > 0 ? getLitros(arr95, "ZCRM") : 0 },
        97: { litros: arr97.length > 0 ? getLitros(arr97, "ZCRM") : 0 },
        Pd: { litros: arrPd.length > 0 ? getLitros(arrPd, "ZCRM") : 0 },
        K: { litros: arrK.length > 0 ? getLitros(arrK, "ZCRM") : 0 },
      };

      return volumenInfo["93"].litros +volumenInfo["95"].litros +volumenInfo["97"].litros +volumenInfo["Pd"].litros +volumenInfo["K"].litros
}

export function getAproximate3FloatString(value){
  let floatNumber = parseFloat(value)
  let localeNumber = floatNumber.toLocaleString("en", {maximumFractionDigits: 3});
  localeNumber = localeNumber.replace(/\./g, '*');
  localeNumber = localeNumber.replace(/,/g, '.');
  localeNumber = localeNumber.replace(/\*/g, ',');
  return localeNumber
}

export function getNumberFromLocalString(value){
  
  // console.log(value)

  if(!value || value === '0')
    return 0

  value = value.replace(/\./g, '')
  value = value.replace(/,/g, '.')
  value = parseFloat(value)
  return value
}

export function getRoundedNumberFromLocalString(value){
  if(!value || value === '0')
    return 0

  value = value.toString()
  value = value.replace(/\./g, '')
  value = value.replace(/,/g, '.')
  value = parseFloat(value)
  value = Math.round(value)
  return value
}

export function getLocaleStringFloat(num) {
  if(num === null || num === undefined)
    return ''
  let n = parseFloat(num)
  let localeNumber = n.toLocaleString("en")
  localeNumber = localeNumber.replace(/\./g, '*')
  localeNumber = localeNumber.replace(/,/g, '.')
  localeNumber = localeNumber.replace(/\*/g, ',')
  return localeNumber
}

export function getLocaleStringRoundedNumber(num) {
  if(num === null || num === undefined)
    return ''
  let n = num.replace(/\./g, '')
  n = n.replace(/,/g, '.')
  n = parseFloat(n)
  n = Math.round(n)
  let localeNumber = n.toLocaleString("en")
  localeNumber = localeNumber.replace(/,/g, '.')
  return localeNumber
}

export function getLocaleStringCeilInt(num) {
  if(num === null || num === undefined)
    return ''

  let n = parseFloat(num)
  n = Math.ceil(n)

  let localeNumber = n.toLocaleString("en")
  localeNumber = localeNumber.replace(/,/g, '.')
  return localeNumber
}

export function getLocaleStringRoundedInt(num) {
  if(num === null || num === undefined)
    return ''

  let n = parseFloat(num)
  n = Math.round(n)

  let localeNumber = n.toLocaleString("en")
  localeNumber = localeNumber.replace(/,/g, '.')

  if(localeNumber === '-0'){
    return '0'
  }
  return localeNumber
}

export function cleanNroOrden(numero_orden) {
  var x=0;
  var nuevo_numero_orden = "";
  for (var i=0; i<numero_orden.length; i++){
    if (numero_orden[i]!=0) {
      x+=1;
    }
    if (x>0) {
      nuevo_numero_orden += numero_orden[i];
    }
  }
  return nuevo_numero_orden
}

export function parseDateString(fechaString){
  const day = fechaString.substring(6,8);
  const month = fechaString.substring(4,6)
  const year = fechaString.substring(0,4);
  return day+"/"+month+"/"+year
}

export const getExcelWithXlsx = (arr, labels, docName) =>{

  let keys = Object.keys(labels)

  let aux = []

  arr.forEach(element => {
    let voidObject = {}
    keys.forEach(key =>voidObject[labels[key]]=element[key]);
    aux.push(voidObject)
  });

  let workSheet = XLSX.utils.json_to_sheet(aux);
  let workBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workBook,workSheet , "Data");
  XLSX.writeFile(workBook, docName, { compression: true });
}

export const getExcelWithXlsx2 = (arr, docName) =>{

  let workBook = XLSX.utils.book_new();

  arr.forEach((hoja) => {
    let aux = [];
    let keys = Object.keys(hoja.labels);
    hoja.transacciones.forEach((element) => {
      let voidObject = {};
      keys.forEach((key) => (voidObject[hoja.labels[key]] = element[key]));
      aux.push(voidObject);
    });
    let workSheet = XLSX.utils.json_to_sheet(aux);
    // const firstRow = workSheet.getRow(1);
    // firstRow.font = {bold: true, color: {argb: 'FFFF0000'}};
    XLSX.utils.book_append_sheet(workBook, workSheet, hoja.nombre);
  });
  // XLSX.writeFile(workBook, "Data.xlsx", { compression: true });
  XLSX.writeFile(workBook, docName, { compression: true });
}

export const getExcelWithOneSheet = (obj, docName, sheetName) => {
  let workBook = XLSX.utils.book_new();
  let aux = [];
  let keys = Object.keys(obj.labels);
  obj.transacciones.forEach((element) => {
    let voidObject = {};
    keys.forEach((key) => (voidObject[obj.labels[key]] = element[key]));
    aux.push(voidObject);
  });
  let workSheet = XLSX.utils.json_to_sheet(aux);
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  XLSX.writeFile(workBook, docName, { compression: true });
}


export const mockDataNewVentasTable = {
  ventas:[
    {
      material: "GAS 93",
      tipo_material: "concesionado",
      venta_propia: {
          material_tipo: [
            "Consignacion"
          ],
          litros_controlador: 1499,
          litros_documentados: 0,
          litros_confirmados: 1499,
          litros_documentados_menos_controlador: -1499,
          litros_confirmados_menos_documentados: 0,
          pesos_controlador: 1986278,
          pesos_documentados: 0,
          pesos_confirmados: 1986278,
          pesos_documentados_menos_controlador: -1986278,
          pesos_confirmados_menos_documentados: 0
      },
      producto_copec: {
        material_tipo: [],
        litros_controlador: 0,
        litros_documentados: 0,
        litros_confirmados: 0,
        litros_documentados_menos_controlador: 0,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 0,
        pesos_documentados: 0,
        pesos_confirmados: 0,
        pesos_documentados_menos_controlador: 0,
        pesos_confirmados_menos_documentados: 0
      },
      total: {
        litros_controlador: 1499,
        litros_documentados: 0,
        litros_confirmados: 1499,
        litros_documentados_menos_controlador: -1499,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 1986278,
        pesos_documentados: 0,
        pesos_confirmados: 1986278,
        pesos_documentados_menos_controlador: -1986278,
        pesos_confirmados_menos_documentados: 0
      },
      producto_copec_alerta: "VERDE",
      producto_copec_alerta_desc: "Alerta verde",
      venta_propia_alerta: "ROJA",
      venta_propia_alerta_desc: "Alerta roja"
    },
    {
      material: "GAS 95",
      tipo_material: "concesionado",
      venta_propia: {
          material_tipo: [
            "Consignacion"
          ],
          litros_controlador: 535,
          litros_documentados: 0,
          litros_confirmados: 535,
          litros_documentados_menos_controlador: -535,
          litros_confirmados_menos_documentados: 0,
          pesos_controlador: 720722,
          pesos_documentados: 0,
          pesos_confirmados: 720722,
          pesos_documentados_menos_controlador: -720722,
          pesos_confirmados_menos_documentados: 0
      },
      producto_copec: {
          material_tipo: [],
          litros_controlador: 0,
          litros_documentados: 0,
          litros_confirmados: 0,
          litros_documentados_menos_controlador: 0,
          litros_confirmados_menos_documentados: 0,
          pesos_controlador: 0,
          pesos_documentados: 0,
          pesos_confirmados: 0,
          pesos_documentados_menos_controlador: 0,
          pesos_confirmados_menos_documentados: 0
      },
      total: {
          litros_controlador: 535,
          litros_documentados: 0,
          litros_confirmados: 535,
          litros_documentados_menos_controlador: -535,
          litros_confirmados_menos_documentados: 0,
          pesos_controlador: 720722,
          pesos_documentados: 0,
          pesos_confirmados: 720722,
          pesos_documentados_menos_controlador: -720722,
          pesos_confirmados_menos_documentados: 0
      },
      producto_copec_alerta: "VERDE",
      producto_copec_alerta_desc: "Alerta verde",
      venta_propia_alerta: "ROJA",
      venta_propia_alerta_desc: "Alerta roja"
    },
    {
      material: "GAS 97",
      tipo_material: "concesionado",
      venta_propia: {
        material_tipo: [
          "Consignacion"
        ],
        litros_controlador: 244,
        litros_documentados: 0,
        litros_confirmados: 244,
        litros_documentados_menos_controlador: -244,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 329519,
        pesos_documentados: 0,
        pesos_confirmados: 329519,
        pesos_documentados_menos_controlador: -329519,
        pesos_confirmados_menos_documentados: 0
      },
      producto_copec: {
        material_tipo: [],
        litros_controlador: 0,
        litros_documentados: 0,
        litros_confirmados: 0,
        litros_documentados_menos_controlador: 0,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 0,
        pesos_documentados: 0,
        pesos_confirmados: 0,
        pesos_documentados_menos_controlador: 0,
        pesos_confirmados_menos_documentados: 0
      },
      total: {
        litros_controlador: 244,
        litros_documentados: 0,
        litros_confirmados: 244,
        litros_documentados_menos_controlador: -244,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 329519,
        pesos_documentados: 0,
        pesos_confirmados: 329519,
        pesos_documentados_menos_controlador: -329519,
        pesos_confirmados_menos_documentados: 0
      },
      producto_copec_alerta: "VERDE",
      producto_copec_alerta_desc: "Alerta verde",
      venta_propia_alerta: "ROJA",
      venta_propia_alerta_desc: "Alerta roja"
    },
    {
      material: "DIESEL",
      tipo_material: "concesionado",
      venta_propia: {
        material_tipo: [
          "Consignacion"
        ],
        litros_controlador: 1688,
        litros_documentados: 22,
        litros_confirmados: 1688,
        litros_documentados_menos_controlador: -1666,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 1874955,
        pesos_documentados: 25000,
        pesos_confirmados: 1874955,
        pesos_documentados_menos_controlador: -1849955,
        pesos_confirmados_menos_documentados: 0
      },
      producto_copec: {
        material_tipo: [],
        litros_controlador: 0,
        litros_documentados: 0,
        litros_confirmados: 0,
        litros_documentados_menos_controlador: 0,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 0,
        pesos_documentados: 0,
        pesos_confirmados: 0,
        pesos_documentados_menos_controlador: 0,
        pesos_confirmados_menos_documentados: 0
      },
      total: {
        litros_controlador: 1688,
        litros_documentados: 22,
        litros_confirmados: 1688,
        litros_documentados_menos_controlador: -1666,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 1874955,
        pesos_documentados: 25000,
        pesos_confirmados: 1874955,
        pesos_documentados_menos_controlador: -1849955,
        pesos_confirmados_menos_documentados: 0
      },
      producto_copec_alerta: "VERDE",
      producto_copec_alerta_desc: "Alerta verde",
      venta_propia_alerta: "ROJA",
      venta_propia_alerta_desc: "Alerta roja"
    },
    {
      material: "KERO",
      tipo_material: "consignado",
      venta_propia: {
        material_tipo: [
          "Concesion"
        ],
        litros_controlador: 124,
        litros_documentados: 0,
        litros_confirmados: 124,
        litros_documentados_menos_controlador: -124,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 124672,
        pesos_documentados: 0,
        pesos_confirmados: 124672,
        pesos_documentados_menos_controlador: -124672,
        pesos_confirmados_menos_documentados: 0
      },
      producto_copec: {
        material_tipo: [],
        litros_controlador: 0,
        litros_documentados: 0,
        litros_confirmados: 0,
        litros_documentados_menos_controlador: 0,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 0,
        pesos_documentados: 0,
        pesos_confirmados: 0,
        pesos_documentados_menos_controlador: 0,
        pesos_confirmados_menos_documentados: 0
      },
      total: {
        litros_controlador: 124,
        litros_documentados: 0,
        litros_confirmados: 124,
        litros_documentados_menos_controlador: -124,
        litros_confirmados_menos_documentados: 0,
        pesos_controlador: 124672,
        pesos_documentados: 0,
        pesos_confirmados: 124672,
        pesos_documentados_menos_controlador: -124672,
        pesos_confirmados_menos_documentados: 0
      },
      producto_copec_alerta: "VERDE",
      producto_copec_alerta_desc: "Alerta verde",
      venta_propia_alerta: "ROJA",
      venta_propia_alerta_desc: "Alerta roja"
    }
  ],
  total:{
    litros_controlador: 5481.9240136146545,
    litros_documentados: 1413.8000049591064,
    litros_confirmados: 5481.800004959106,
    litros_documentados_menos_controlador: -4068.124008655548,
    litros_confirmados_menos_documentados: -0.1240086555480957,
    pesos_controlador: 6628423,
    pesos_documentados: 1617277,
    pesos_confirmados: 6628423,
    pesos_documentados_menos_controlador: -5011146,
    pesos_confirmados_menos_documentados: 0
  }
}

export function toDateFromAmerican(american_date) {
  const [year, month, day] = american_date.split('-')

  return new Date(year, month - 1, day)
}
