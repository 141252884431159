import React, { useState, useEffect, createContext, useRef } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import Measure from "react-measure";
import Resumen from "./components/Resumen";
import Pagos from "./components/Pagos";
import Cartola from "./components/Cartola";
import HistorialPagos from "./components/HistorialPagos";
import LiberarPagos from "./components/LiberarPagos";
import FormularioPedidos from "./components/Pedidos/FormularioPedidos";
import HistorialPedidos from "./components/Pedidos/HistorialPedidos";
import "./App.scss";
import { AbonarEnBanco } from "./components/AbonarEnBanco/AbonarEnBanco";
import PedidosActivos from "./components/Pedidos/PedidosActivos";
import { getFixedBottomCoordinate } from "./components/utils/utils";
import Bonificacion from "./components/Pedidos/Bonificacion";
import ComprobanteTrx from "./components/StepperComponents/ComprobanteTrx";
import { HistorialDeModificaciones } from "./components/HistorialDeModificaciones";
import { HistorialDeAlarmas } from "./components/HistorialDeAlarmas";
import { ConfiguracionAlarmas } from "./components/ConfiguracionAlarmas";
import { ConfiguracionTanquesConcesionario } from "./components/FluctuacionesConfiguracionTanques/ConfiguracionTanquesConcesionario";
import { ConfiguracionTanquesTelesoporte } from "./components/FluctuacionesConfiguracionTanques/ConfiguracionTanquesTelesoporte";
import { FluctuacionesIngresoMediciones } from "./components/FluctuacionesIngresoMediciones/FluctuacionesIngresoMediciones";
import {
  displayLoader,
  scrollTo,
  showGreyBackground,
  hideGreyBackground,
  getPageYOffset
} from "./components/utils/windows";
import ArchivoArcoprime from "./components/ArchivoArcoprime";
import { serviceCall } from './components/utils/services';
import { Alert } from 'pharedata-custom-components';
import OTCobrosPorFormular from "./components/Mantenimiento/OTCobrosPorFormular";
import AvisosDeMantemiento from "./components/Mantenimiento/AvisosDeMantenimiento";
import CierreDiario from "./components/CierreDiario/CierreDiario";
import Reapertura from "./components/CierreDiario/Reapertura";
import CierreMensual from "./components/CierreMensual/CierreMensual";
import HistorialRegularizacion from "./components/CierreDiario/HistorialRegularizacion/HistorialRegularizacion";
import Lubricantes from "./components/TctTae/Lubricantes";
import Bluemax from "./components/TctTae/Bluemax";
import BluemaxDetail from "./components/TctTae/BluemaxDetail";
import Regularizacion from "./components/CierreDiario/Regularizacion";
import HistorialFluctuaciones from "./components/CierreDiario/HistorialFluctuaciones/HistorialFluctuaciones";
import ConfirmarGuiasManuales from "./components/TctTae/ConfirmarGuiasManuales/ConfirmarGuiasManuales";
import ConsultarGuiasManuales from "./components/TctTae/ConsultarGuiasManuales/ConsultarGuiasManuales";
import AutorizarConsumo from "./components/TctTae/AutorizarConsumo/AutorizarConsumo";
import TrxProducto from "./components/TctTae/TRXProducto/TrxProducto";
import InformeFacturacion from "./components/FacturacionFlujo/InformeFacturacion";
import Prefactura from "./components/Prefactura/Prefactura";

export const ViewPortContext = createContext(0);

function App() {
  const [token, setToken] = useState(null);
  const [rutConcesionario, setRutConcesionario] = useState();
  const [params, setParams] = useState();
  const [viewPortHeight, setViewPortHeight] = useState(0);
  const [iframeHeight, setIframeHeight] = useState(600);
  const [isROImpersonate, setIsROImpersonate] = useState(false)
  const bottomCoordinate = getFixedBottomCoordinate(
    viewPortHeight,
    iframeHeight
  );
  const [cabecera, setCabecera] = useState("");
  const [pagador, setPagador] = useState("");
  const [instalaciones, setInstalaciones] = useState("");
  const [edsPortal, setEdsPortal] = useState()
  const todayDate = new Date();
  const [displayCustomAlert, setDisplayCustomAlert] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('')
  const [modalIcon, setModalIcon] = useState()
  const [pageYOffset, setPageYOffset] = useState(0)
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT)

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    window.parent.postMessage({ getToken: true }, "*");
    window.parent.postMessage({ getViewPortHeight: true }, "*");
    window.parent.postMessage({ getIsROImpersonate: true}, '*');
  }, []);

  useEffect(() => {
    window.parent.postMessage({ checkIframeSrc: window.location.href }, "*");
  }, [window.location]);

  function receiveMessage(event) {
    const message = event.data;
    const token = message.token;
    const newUser = message.user;
    const params = message.params;
    const viewPortHeight = message.viewPortHeight;

    if (token) {
      setToken(token);
      getUser(token);
    }
    if (newUser) {
      setRutConcesionario(newUser.rut_concesionario);
    }
    if (params) {
      setParams(params);
    }
    if (viewPortHeight) {
      setViewPortHeight(viewPortHeight);
    }
    if (message.isROImpersonate !== undefined) {
      setIsROImpersonate(message.isROImpersonate);
    }

    if(message.pageYOffset || message.pageYOffset === 0){
      setPageYOffset(message.pageYOffset)
    }
  }

  function display403Error(){
    setModalTitle("Importante")
    setModalText("No tienes permiso para realizar esta acción.")
    setModalIcon("importante")
    getPageYOffset()
    setDisplayCustomAlert(true)
  }

  function displayGenericError(code){
    setModalTitle("Error")
    setModalText(`Se ha producido un problema, reintente más tarde. Si el problema persiste comuníquese con el call center e indique \"Error ${code}\".`)
    setModalIcon("error")
    getPageYOffset()
    setDisplayCustomAlert(true)
  }

  function displayCustomError(title, text, icon){
    setModalTitle(title)
    setModalText(text)
    setModalIcon(icon)
    getPageYOffset()
    setDisplayCustomAlert(true)
  }

  function closeCustomAlert(){
    setDisplayCustomAlert(false)
  }

  async function getUser(token) {
    window.parent.postMessage({ getUser: true }, "*");
  }

  function resize(bounds) {
    setIframeHeight(Math.max(bounds.height, 600));
    window.parent.postMessage({ heightChange: bounds.height }, "*");
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        if (rutConcesionario === "77215640-5") { // If is arcoprime
          getEdsPortal();
        }
        else{
          checkCabecera();
        }
      }
    } else {
      if (token) {
        if (rutConcesionario === "77215640-5" /* || true */) { // If is arcoprime
          getEdsPortal();
        }else{
          checkCabecera();
        }
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if(edsPortal){
      if(edsPortal.data.user_eds && edsPortal.data.user_eds.length > 0){
        getCabecera("1"+ parseInt(edsPortal.data.user_eds[0]))
      }
    }
  }, [edsPortal])

  useEffect(() => {
    if(pagador){
      checkInstalaciones();
    }
  }, [pagador]);

  function checkCabecera() {
    let rawCabecera;
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      rawCabecera = window.localStorage.getItem("cabecera" + rutConcesionario);
    } else {
      rawCabecera = window.localStorage.getItem("cabecera12345678-9");
    }

    const infoCabecera = JSON.parse(rawCabecera);

    if (rawCabecera) {
      const fechaCabecera = new Date(infoCabecera.FECHA);

      if (
        fechaCabecera.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)
      ) {
        setCabecera(infoCabecera);
        if (infoCabecera.DATOS_CLIENTE && infoCabecera.DATOS_CLIENTE.PAGADOR)
          setPagador(infoCabecera.DATOS_CLIENTE.PAGADOR);
      } else {
        if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
          window.localStorage.removeItem("cabecera" + rutConcesionario);
        } else {
          window.localStorage.removeItem("cabecera12345678-9");
        }
        getCabecera();
      }
    } else {
      getCabecera();
    }
  }

  function checkInstalaciones() {
    let rawInstalaciones;
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      rawInstalaciones = window.localStorage.getItem(
        "instalaciones" + rutConcesionario
      );
    } else {
      rawInstalaciones = window.localStorage.getItem("instalaciones12345678-9");
    }

    const infoInstalaciones = JSON.parse(rawInstalaciones);

    if (rawInstalaciones) {
      const fechaInstalaciones = new Date(infoInstalaciones.FECHA);

      if (fechaInstalaciones.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
        setInstalaciones(infoInstalaciones);
      } else {
        if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
          window.localStorage.removeItem("instalaciones" + rutConcesionario);
        } else {
          window.localStorage.removeItem("instalaciones12345678-9");
        }
        getInstalaciones(token);
      }
    } else {
      getInstalaciones(token);
    }
  }

  async function getCabecera(pagadorArcoprime = null) {
    displayLoader();
    let url = process.env.REACT_APP_BASE_API + "/cuenta-corriente/datos-cabecera?rut_concesionario="
    
    if(pagadorArcoprime){
      url += pagadorArcoprime
    }
    else{
      url += rutConcesionario
    }

    let result = await serviceCall(url, "GET", token, display403Error, displayGenericError)

    if(result !== 'error'){
      setCabecera(result.data);
      if (result.data.DATOS_CLIENTE && result.data.DATOS_CLIENTE.PAGADOR)
        setPagador(result.data.DATOS_CLIENTE.PAGADOR);

      let storage = result.data;
      storage.FECHA = new Date();

      if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
        window.localStorage.setItem(
          "cabecera" + rutConcesionario,
          JSON.stringify(storage)
        );
      } else {
        window.localStorage.setItem(
          "cabecera12345678-9",
          JSON.stringify(storage)
        );
      }
    }
  }

  async function getEdsPortal () {
    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/eds-portal";

    console.log("getting eds portal")

    let result = await serviceCall(url, "GET", token, display403Error, displayGenericError)
    console.log("eds portal::")
    console.log(result)
    if(result !== "error"){
      setEdsPortal(result)
    }
  }

  async function callServiceHandler(url, method, data = null, displayAndHideLoader = true){
    return await serviceCall(url, method, token, display403Error, displayGenericError, data, displayAndHideLoader)
  }

  async function getInstalaciones(token) {
    displayLoader();

    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-instalaciones-cliente?id_pagador=" +
      pagador;

    let result = await serviceCall(url, "GET", token, display403Error, displayGenericError)
    if(result !== "error"){
      setInstalaciones(result.data);
      let storage = result.data;
      storage.FECHA = new Date();

      if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
        window.localStorage.setItem(
          "instalaciones" + rutConcesionario,
          JSON.stringify(storage)
        );
      } else {
        window.localStorage.setItem(
          "instalaciones12345678-9",
          JSON.stringify(storage)
        );
      }
    }
  }

  return (
    <div className="App">
      <Measure
        bounds
        onResize={(contentRect) => {
          resize(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <Router history={history}>
              <ViewPortContext.Provider
                value={{ viewPortHeight, bottomCoordinate, iframeHeight }}
              >
                <Switch>
                  <Route
                    path="/resumen"
                    render={(props) => (
                      <Resumen
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        isROImpersonate={isROImpersonate}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/pagos"
                    render={(props) => (
                      <Pagos
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        params={params}
                        cabecera={cabecera}
                        isROImpersonate={isROImpersonate}
                        callServiceHandler={callServiceHandler}
                        isErrorAlertOpen={displayCustomAlert}
                        displayCustomError={displayCustomError}
                      />
                    )}
                  />
                  <Route
                    path="/historial-pagos"
                    render={(props) => (
                      <HistorialPagos
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        isROImpersonate={isROImpersonate}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/liberar-pagos"
                    render={(props) => (
                      <LiberarPagos
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        isROImpersonate={isROImpersonate}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/cartola"
                    render={(props) => (
                      <Cartola
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        params={params}
                        cabecera={cabecera}
                        isROImpersonate={isROImpersonate}
                        edsPortal={edsPortal}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/abonar-en-banco"
                    render={(props) => (
                      <AbonarEnBanco
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/descarga-archivo-pagos"
                    render={(props) => (
                      <ArchivoArcoprime
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        callServiceHandler={callServiceHandler}
                        displayCustomError={displayCustomError}
                        isROImpersonate={isROImpersonate}
                      />
                    )}
                  />
                  <Route
                    path="/formulario-pedidos"
                    render={(props) => (
                      <FormularioPedidos
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        params={params}
                        pagador={pagador}
                        setPagador={setPagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        edsPortal={edsPortal}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/historial-pedidos"
                    render={(props) => (
                      <HistorialPedidos
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        pagador={pagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                        edsPortal={edsPortal}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/pedidos-activos"
                    render={(props) => (
                      <PedidosActivos
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        pagador={pagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                        edsPortal={edsPortal}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/bonificacion-preprograma"
                    render={(props) => (
                      <Bonificacion
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecerae={cabecera}
                        pagador={pagador}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/exito-trx"
                    render={(props) => (
                      <ComprobanteTrx
                        {...props}
                        token={token}
                        params={params}
                        isROImpersonate={isROImpersonate}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/fracaso-trx"
                    render={(props) => (
                      <ComprobanteTrx
                        {...props}
                        token={token}
                        params={params}
                        isROImpersonate={isROImpersonate}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/contingencia-trx"
                    render={(props) => (
                      <ComprobanteTrx
                        {...props}
                        token={token}
                        params={params}
                        isROImpersonate={isROImpersonate}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/historial-de-modificaciones"
                    render={(props) => (
                      <HistorialDeModificaciones
                        {...props}
                        rutConcesionario={rutConcesionario}
                        token={token}
                        params={params}
                        isROImpersonate={isROImpersonate}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/historial-de-alarmas"
                    render={(props) => (
                      <HistorialDeAlarmas
                        {...props}
                        token={token}
                        params={params}
                        rutConcesionario={rutConcesionario}
                        isROImpersonate={isROImpersonate}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/configuracion-de-alarmas"
                    render={(props) => (
                      <ConfiguracionAlarmas
                        {...props}
                        token={token}
                        params={params}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/configuracion-tanques-concesionario"
                    render={(props) => (
                      <ConfiguracionTanquesConcesionario
                        {...props}
                        token={token}
                        params={params}
                        rutConcesionario={rutConcesionario}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/configuracion-tanques-telesoporte"
                    render={(props) => (
                      <ConfiguracionTanquesTelesoporte
                        {...props}
                        token={token}
                        params={params}
                        rutConcesionario={rutConcesionario}
                        callServiceHandler={callServiceHandler}
                        cabecera={cabecera}
                      />
                    )}
                  />
                  <Route
                    path="/fluctuaciones-ingreso-mediciones"
                    render={(props) => (
                      <FluctuacionesIngresoMediciones
                        {...props}
                        token={token}
                        params={params}
                        rutConcesionario={rutConcesionario}
                        viewPortHeight={viewPortHeight}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/ot-cobros-por-formular"
                    render={(props) => (
                      <OTCobrosPorFormular
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        pagador={pagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                        edsPortal={edsPortal}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/avisos-de-mantenimiento"
                    render={(props) => (
                      <AvisosDeMantemiento
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        pagador={pagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                        edsPortal={edsPortal}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/lubricantes"
                    render={(props) => (
                      <Lubricantes
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/bluemax"
                    render={(props) => (
                      <Bluemax
                      {...props}
                      token={token}
                      rutConcesionario={rutConcesionario}
                      cabecera={cabecera}
                      callServiceHandler={callServiceHandler}
                      instalaciones={instalaciones}
                      isROImpersonate={isROImpersonate}
                      />
                      )}
                  />
                  <Route
                    path="/confirmar-guias-manuales"
                    render={(props) => (
                      <ConfirmarGuiasManuales
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        callServiceHandler={callServiceHandler}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                      />
                    )}
                  />
                  <Route
                    path="/consultar-guias-manuales"
                    render={(props) => (
                      <ConsultarGuiasManuales
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        callServiceHandler={callServiceHandler}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                      />
                    )}
                  />
                  <Route
                    path="/autorizacion-consumo"
                    render={(props) => (
                      <AutorizarConsumo
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        callServiceHandler={callServiceHandler}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                      />
                    )}
                  />
                  <Route
                    path="/informe-facturacion"
                    render={(props) => (
                      <InformeFacturacion
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        callServiceHandler={callServiceHandler}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                      />
                    )}
                  />
                  <Route
                    path="/prefactura"
                    render={(props) => (
                      <Prefactura
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        callServiceHandler={callServiceHandler}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                      />
                    )}
                  />
                  <Route
                    path="/trx-producto"
                    render={(props) => (
                      <TrxProducto
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        callServiceHandler={callServiceHandler}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                      />
                    )}
                  />
                  
                  <Route
                    path="/bluemax-detail/:type"
                    render={(props) => (
                      <BluemaxDetail
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        cabecera={cabecera}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />

                  <Route
                    path="/cierre-diario"
                    render={(props) => (
                      <CierreDiario
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        pagador={pagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                        edsPortal={edsPortal}
                        callServiceHandler={callServiceHandler}
                        params={params}
                        setPagador={setPagador}
                      />
                    )}
                  />

                  <Route
                    path="/reapertura"
                    render={(props) => (
                      <Reapertura
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        pagador={pagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                        edsPortal={edsPortal}
                        callServiceHandler={callServiceHandler}
                        params={params}
                      />
                    )}
                  />

                  <Route
                    path="/cierre-mensual"
                    render={(props) => (
                      <CierreMensual
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        pagador={pagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        callServiceHandler={callServiceHandler}
                        edsPortal={edsPortal}
                        setPagador={setPagador}
                      />
                    )}
                  />

                  <Route
                    path="/historial-regularizacion"
                    render={(props) => (
                      <HistorialRegularizacion
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        pagador={pagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                        edsPortal={edsPortal}
                        callServiceHandler={callServiceHandler}
                      />
                    )}
                  />
                  <Route
                    path="/regularizacion"
                    render={(props) => (
                      <Regularizacion
                        {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        pagador={pagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        isROImpersonate={isROImpersonate}
                        edsPortal={edsPortal}
                        callServiceHandler={callServiceHandler}
                        params={params}
                        setPagador={setPagador}
                      />
                    )}
                  />
                  <Route
                    path="/historial-fluctuaciones"
                    render={(props) => (
                      <HistorialFluctuaciones
                      {...props}
                        token={token}
                        rutConcesionario={rutConcesionario}
                        pagador={pagador}
                        cabecera={cabecera}
                        instalaciones={instalaciones}
                        callServiceHandler={callServiceHandler}
                        edsPortal={edsPortal}
                        setPagador={setPagador}
                      />
                      
                    )}
                  />
                  <Route path="/" exact />
                </Switch>
              </ViewPortContext.Provider>
            </Router>
            <Alert
              displayModal={displayCustomAlert}
              closeModal={closeCustomAlert}
              modalTitle={modalTitle}
              modalText={modalText}
              modalIcon={modalIcon}
              acceptFunc={closeCustomAlert}
              acceptText="Aceptar"
              showGreyBackground={showGreyBackground}
              hideGreyBackground={hideGreyBackground}
              scrollTo={scrollTo}
              pageYOffset={pageYOffset}
              isDesktopRef={isDesktopRef.current}
            />
          </div>
        )}
      </Measure>
    </div>
  );
}

export default App;
